import React, { useEffect, useMemo, useReducer, useState } from 'react';
import RecipesTable, { ColumnDefinition, RecipeRow } from './RecipesTable';
import { Button, Checkbox, FormControlLabel, Menu, Stack, TextField } from '@mui/material';
import { TagsContext } from '../contexts/TagsContext';
import { EditRecipeDispatchContext, editRecipesReducer, Action as EditRecipeAction } from '../contexts/EditRecipeDispatch';

export interface RecipesViewProps<RecipeT> {
    recipes: RecipeRow<RecipeT>[] | null;
    extraColumns?: ColumnDefinition<RecipeRow<RecipeT>>[];
}

interface FilterableTag {
    name: string;
    count: number;
}

function RecipesView<T>({ recipes, extraColumns }: RecipesViewProps<T>) {
    const [filterTags, setFilterTags] = useState<Set<string>>(new Set());
    const [query, setQuery] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    let [context, dispatch] = useReducer<React.Reducer<RecipeRow<T>[] | null, EditRecipeAction<RecipeRow<T>> >>(editRecipesReducer, recipes);
    useEffect(() => {
        dispatch({ type: 'set', context: recipes });
    }, [recipes, dispatch]);

    const tags = useMemo<FilterableTag[]>(() => {
        const tags = new Map<string, number>();

        if (context) {
            for (const recipe of context) {
                if (!recipe.tags) continue;

                for (const tag of recipe.tags) {
                    tags.set(tag, (tags.get(tag) ?? 0) + 1);
                }
            }
        }

        return Array.from(tags.entries()).map(([name, count]) => ({ name, count }));
    }, [context]);
    const tagNames = useMemo(() => tags.map(t => t.name).sort((a, b) => a.localeCompare(b)), [tags]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (context) {
        if (query) {
            const lowerQuery = query.toLowerCase();
            context = context.filter(r => r.name.toLowerCase().indexOf(lowerQuery) !== -1);
        }
        if (filterTags.size !== 0) {
            context = context.filter(r => r.tags?.some(t => filterTags.has(t)));
        }
    }

    return (<TagsContext.Provider value={tagNames}>
        <EditRecipeDispatchContext.Provider value={dispatch as any}>
            <Stack direction='row' spacing='5px' alignItems='center' justifyContent='end' marginRight='5px'>
                <Button variant='outlined' href='/recipe-edit'>Nytt</Button>
                <Button variant='outlined' onClick={handleClick}>Filtrera</Button>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <Stack direction='column' marginLeft='15px' marginRight='15px'>
                        {tags.map(({ name, count }) => {
                            const label = `${name} (${count})`;
                            const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                    filterTags.add(name);
                                }
                                else {
                                    filterTags.delete(name);
                                }
                                setFilterTags(new Set(filterTags));
                            }
                            return <FormControlLabel
                                key={name}
                                control={<Checkbox checked={filterTags.has(name) || false} onChange={onChange}/>}
                                label={label}
                            ></FormControlLabel>;
                        })}
                    </Stack>
                </Menu>
                <TextField variant='standard' label='Sök' value={query} onChange={event => setQuery(event.target.value)}></TextField>
                <div>Visar {context?.length ?? 0} recept</div>
            </Stack>
            <RecipesTable recipes={context} extraColumns={extraColumns}></RecipesTable>
        </EditRecipeDispatchContext.Provider>
    </TagsContext.Provider>);
}

export default RecipesView;
