import { createContext, Dispatch, useContext } from "react";
import { loadRecipeRaw, saveRecipe as saveRecipeRequest, Recipe, saveRecipeTags } from "../data";

export interface SetContext<T> {
    type: 'set';
    context: T[] | null;
}

export interface SetTags {
    type: 'tags';
    recipeId: number;
    tags: string[];
}

export type Action<T extends Recipe> = SetContext<T> | SetTags;

export const EditRecipeDispatchContext = createContext<Dispatch<Action<Recipe>>>(null!);

export function useEditRecipeDispatch() {
    return useContext(EditRecipeDispatchContext);
}

export function editRecipesReducer<T extends Recipe>(context: T[] | null, action: Action<T>): T[] | null {
    switch (action.type) {
        case "set": {
            context = action.context;
            break;
        }

        case "tags": {
            if (context) {
                const index = context.findIndex(r => r.id === action.recipeId);
                if (index !== -1) {
                    const recipe = context[index];
                    saveRecipeTags({ id: recipe.id, tags: action.tags });
                    context = [
                        ...context.slice(0, index),
                        { ...context[index], tags: action.tags },
                        ...context.slice(index + 1),
                    ];
                }
            }
            break;
        }
    }
    return context;
}
