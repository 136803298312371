import React, { ReactElement } from 'react';
import "./ShoppingList.css";
import { CooklangNumber, QuantityValue } from '../data';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "@hello-pangea/dnd";
import { ShoppingListCategory, ShoppingListIngredient, useShoppingList, useShoppingListDispatch } from '../contexts/ShoppingListContext';
import { capitalizeFirst } from '../textFormattings';

export type IngredientInfoBuilder = (ingredient: ShoppingListIngredient) => ReactElement;

function round(num: number): string {
    const diff = num - Math.trunc(num);
    if (diff < 0.01) {
        return "" + Math.trunc(num)
    }

    return num.toFixed(2);
}

function displayCooklangNumber(v: CooklangNumber, fractionAsNumber?: boolean): string {
    switch (v.type) {
        case "regular":
            return round(v.value);

        case "fraction":
            const { whole, num, den } = v.value;
            if (fractionAsNumber) {
                const value = whole + num / den;
                return round(value);
            }

            if (whole) {
                return `${whole} ${num}/${den}`;
            }

            return `${num}/${den}`;
    }
}

export function displayQuantityValue(v: QuantityValue, fractionAsNumber?: boolean): string {
    switch (v.type) {
        case "number":
            return displayCooklangNumber(v.value, fractionAsNumber);

        case "range":
            const start = displayCooklangNumber(v.start, fractionAsNumber);
            const end = displayCooklangNumber(v.end, fractionAsNumber);
            return `${start} - ${end}`;

        case "text":
            return v.value;
    }
}

interface IngredientProps {
    ingredient: ShoppingListIngredient;
    index: number;
    onRemove: (ingredient: ShoppingListIngredient) => void;
    onChangeValue: (ingredient: ShoppingListIngredient, value: string) => void;
    buildIngredientInfo?: IngredientInfoBuilder;
}

function Ingredient({ ingredient, index, onRemove, onChangeValue, buildIngredientInfo }: IngredientProps) {
    return <Draggable key={ingredient.uniqueId} draggableId={ingredient.uniqueId} index={index}>
        {(provided, snapshot) => (
            <div
                className='shopping-list-ingredient'
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div>
                    <input
                        className='shopping-list-ingredient-input'
                        type='text'
                        value={ingredient.quantity ? displayQuantityValue(ingredient.quantity) : ""}
                        onChange={(e) => onChangeValue(ingredient, e.target.value)}></input>
                    {ingredient.unit}
                </div>
                <div className='name'>
                    <div>{capitalizeFirst(ingredient.name)}</div>
                    <div>{buildIngredientInfo?.(ingredient) ?? <></>}</div>
                </div>
                <div className='remove'>
                    <button onClick={() => onRemove(ingredient)}>Remove</button>
                </div>
            </div>
        )}
    </Draggable>;
}

interface ShoppingCategoryProps {
    category: ShoppingListCategory;
    buildIngredientInfo?: IngredientInfoBuilder;
}
function ShoppingCategory({ category, buildIngredientInfo }: ShoppingCategoryProps) {
    const dispatch = useShoppingListDispatch();

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
    });
    return (
        <div className='shopping-list-category'>
            <div className='shopping-list-category-header'>{category.name || "Ingen kategori"}</div>
            <Droppable droppableId={category.uniqueId}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className='shopping-list-category-droppable'
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {category.items.map((i, index) => {
                            const onRemove = () => {
                                dispatch({ type: 'remove', category: category.uniqueId, index });
                            };
                            const onChangeValue = (_ingredient: ShoppingListIngredient, value: string) => {
                                dispatch({ type: 'change-value', category: category.uniqueId, index, value });
                            };
                            return <Ingredient
                                key={i.uniqueId}
                                ingredient={i}
                                index={index}
                                onRemove={onRemove}
                                onChangeValue={onChangeValue}
                                buildIngredientInfo={buildIngredientInfo}
                            ></Ingredient>;
                        })}
                        {provided.placeholder ? <div style={{ gridColumn: "span 3" }}>{provided.placeholder}</div> : <></>}
                    </div>
                )}
            </Droppable>
        </div>
    );
}

export interface ShoppingListProps {
    buildIngredientInfo?: IngredientInfoBuilder;
}

function ShoppingList({ buildIngredientInfo }: ShoppingListProps) {
    const { shoppingList: current } = useShoppingList();
    const dispatch = useShoppingListDispatch();

    const onCopy = () => {
        let items: string[] = [];
        for (const c of current) {
            for (const i of c.items) {
                const text = [
                    i.quantity ? displayQuantityValue(i.quantity) : undefined,
                    i.unit,
                    capitalizeFirst(i.name)
                ].filter(p => !!p).join(" ");
                items.push(text);
            }
        }
        const text = items.join("\n");
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        }
        else {
            console.log(text);
            alert(text);
        }
    };

    const onDragEnd: OnDragEndResponder = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const sInd = source.droppableId;
        const dInd = destination.droppableId;
        dispatch({
            type: 'move',
            fromCategory: sInd,
            toCategory: dInd,
            fromIndex: source.index,
            toIndex: destination.index
        });
    };

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className='shopping-list-wrapper'>
                    {current.map((c) => {
                        return (<ShoppingCategory
                            key={c.uniqueId}
                            category={c}
                            buildIngredientInfo={buildIngredientInfo}
                        ></ShoppingCategory>);
                    })}
                </div>
            </DragDropContext>
            <div>
                <button onClick={onCopy}>Copy</button>
                <button onClick={onCopy}>Ny kategori</button>
            </div>
        </div>
    );
}

export default ShoppingList;