import React, { useEffect, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import "./EditRecipeView.css";
import imageIcon from "../icons/image.png";
import EditRecipe from '../components/EditRecipe';
import { CooklangRecipe, RecipeWithImages, imageIdToUrl, loadRecipeRaw, parseRecipe, saveRecipe, uploadImage } from '../data';
import Recipe, { ParsedRecipeWithMetadata } from '../components/Recipe';


export async function loader({ request }: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const id = url.searchParams.get("id") || "";
    if (id) {
        const recipeId = parseInt(id, 10);
        if (recipeId) {
            const recipe = await loadRecipeRaw(recipeId);
            return { recipe };
        }
    }
    
    return {};
}

interface Image {
    dataURL?: string;
    file?: File;
    imageId?: number;
}

interface Slot {
    image?: Image | null;
    type: "main" | "step";
    index: number;
}

function imageToUrl(image: Image | undefined | null): string | undefined {
    if (!image) return undefined;
    if (image.dataURL) return image.dataURL;

    return imageIdToUrl(image.imageId);
}

function toImageArray(recipe: RecipeWithImages | undefined): (Image | null)[] {
    if (!recipe) return [];

    const maxIndex = recipe.images.reduce((max, i) => Math.max(max, i.step), 0);
    const images = new Array<(Image | null)>(maxIndex + 1).fill(null);
    if (typeof recipe.recipe.image_id === "number") {
        images[0] = { imageId: recipe.recipe.image_id };
    }
    for (const i of recipe.images) {
        images[i.step + 1] = { imageId: i.image_id };
    }

    return images;
}

function withImageUrls(recipe: CooklangRecipe, images: (Image | null)[]): ParsedRecipeWithMetadata {
    const stepImages = new Map<number, string>();
    for (let i = 1; i < images.length; ++i) {
        const imageUrl = imageToUrl(images[i]);
        if (imageUrl) {
            stepImages.set(i, imageUrl);
        }
    }

    return {
        recipe: recipe,
        image: imageToUrl(images[0]),
        stepImages
    };
}

function ImageButton({ image, onImageChanged }: { image?: Image | null, onImageChanged: (image?: Image & { imageId?: number; }) => void }) {
    const onChange = async (imageList: ImageListType, addUpdateIndex?: number[] | undefined) => {
        // data for submit
        let image = imageList[0];
        onImageChanged(image);

        if (image?.file) {
            image.imageId = await uploadImage(image.file);
            onImageChanged(image);
        }
    };

    return <ImageUploading
        value={image ? [image] : []}
        onChange={onChange}
        dataURLKey='data_url'
        maxFileSize={2048*1024}>
        {
            ({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => {
                let classNames = ["upload_image_button-wrapper"];
                if (isDragging) {
                    classNames.push("dragging");
                }

                const image = imageList[0];
                const imageUrl = image?.['data_url'] || imageIdToUrl(image?.['imageId']);
                // write your building UI
                return <div className={classNames.join(" ")} onClick={onImageUpload} {...dragProps}>
                    <img src={imageUrl || imageIcon} alt="" width={imageUrl ? "100" : "30"} />
                    {image ? <button onClick={(e) => { e.stopPropagation(); onImageRemoveAll();}}>Remove</button> : <></>}
                </div>
            }
        }
    </ImageUploading>
}

function ImageHandler({ slots, onImageChanged }: { slots: Slot[]; onImageChanged: (index: number, image?: Image) => void }) {
    return <div className="upload__image-wrapper">
        {slots.map((s, index) => {
            return <ImageButton key={index} image={s.image} onImageChanged={image => onImageChanged(index, image)}></ImageButton>
        })}
    </div>
}


function EditRecipeView() {
    const { recipe } = useLoaderData() as { recipe: RecipeWithImages | undefined; };
    const [id, setId] = useState<number | undefined>(recipe?.recipe.id);

    const [oldName, setOldName] = useState<string>(recipe?.recipe.name || "");
    const [oldText, setOldText] = useState<string>(recipe?.recipe.recipe || "");
    const [oldImages, setOldImages] = useState<(Image | null)[]>(toImageArray(recipe));

    const [name, setName] = useState<string>(oldName);
    const [text, setText] = useState<string>(oldText);
    const [images, setImages] = useState<(Image | null)[]>(oldImages);

    const hasChanges = oldName !== name || oldText !== text;
    const allImagesAreUploaded = images.every(i => !i || typeof i.imageId == "number");

    const [parsedRecipe, setParsedRecipe] = useState<CooklangRecipe | undefined>(undefined);
    const [isParsingRecipe, setIsParsingRecipe] = useState<boolean>(false);

    const requestParseRecipe = (text: string) => {
        setIsParsingRecipe(true);
        parseRecipe(text).then(res => {
            setParsedRecipe(res);
        })
        .finally(() => {
            setIsParsingRecipe(false);
        });
    };

    useEffect(() => {
        if (parsedRecipe || isParsingRecipe) return;

        requestParseRecipe(text);
    }, [parsedRecipe, isParsingRecipe, text]);

    const onSave = async () => {
        const recipeId = await saveRecipe({
            id,
            name,
            images: images.map(i => i ? i.imageId! : null),
            recipe: text
        });
        setId(recipeId);
        setOldName(name);
        setOldText(text);
    };

    const onTextChanged = (text: string) => {
        setText(text);
        if (isParsingRecipe) {
            return;
        }
        requestParseRecipe(text);
    };

    const onImageChanged = (index: number, image?: Image) => {
        const newImage = images.slice();
        if (!image) {
            delete newImage[index];
        }
        else {
            newImage[index] = image;
        }
        setImages(newImage);
    };

    let slots: Slot[] = [
        { type: "main", index: 0, image: images[0] }
    ];

    if (parsedRecipe) {
        for (const section of parsedRecipe.sections) {
            for (const _ of section.content) {
                const index = slots.length;
                slots.push({ type: "step", index, image: images[index] });
            }
        }
    }

    return (
        <div className='edit-recipes-view-wrapper'>
            <div className='editor-toolbar-wrapper'>
                <input type="text" value={name} onChange={e => setName(e.target.value)}></input>
                <button onClick={onSave} disabled={!name || !allImagesAreUploaded}>Save{hasChanges ? "*" : ""}</button>
            </div>
            <div className='editor-preview'>
                <div className='editor-preview-item'>
                    <div className='editor-preview-item-header'>
                        Editor (<a href='https://cooklang.org/docs/spec/'>Cooklang</a>)
                    </div>
                    <EditRecipe text={text} onTextChanged={onTextChanged}></EditRecipe>
                </div>
                <div className='editor-preview-item'>
                    <div className='editor-preview-item-header'>
                        Preview
                    </div>
                    {parsedRecipe ? <Recipe recipe={withImageUrls(parsedRecipe, images)}></Recipe> : <>Loading...</>}
                </div>
            </div>
            <ImageHandler slots={slots} onImageChanged={onImageChanged}></ImageHandler>
        </div>
    );
}

export default EditRecipeView;
