import React from "react";
import './LoginPage.css';
import GoogleLoginButton from "./GoogleLoginButton";
import { Typography } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { AuthStatus, useAuthContext } from "../contexts/AuthProvider";

const errorMapping: { [key: string]: string; } = {
    'internal-error': "Something went wrong trying to log you in. Please try again later.",
    'no-identity': "Could not determine your identity.",
    'not-invited': "Sorry, you are not allowed to login.",
    'no-email': "Could not determine your email address."
};

export default function LoginPage() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const authState = useAuthContext();
    const loginError = searchParams.get("login-error");

    let redirectUrl: string = "";
    let errorContent;
    if (loginError) {
        errorContent = <div className="login-page-error">
            {errorMapping[loginError as string] ?? loginError}
        </div>;
    }
    else if (authState.status === AuthStatus.Error) {
        errorContent = <div className="login-page-error">
            {authState.error}
        </div>;
    }
    else if (location.pathname.startsWith("/join/")) {
        redirectUrl = location.pathname;
        errorContent = <div className="login-page-error">
            You need to be logged in to accept an invite
        </div>;
    }

    return <div className="login-page-wrapper">
        <Typography
            variant="h1"
            noWrap
            sx={{
                mt: "15%",
                mb: "5%",
                display: 'flex',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'white',
                textDecoration: 'none',
            }}
        >
            MATPLANERING
        </Typography>
        <GoogleLoginButton redirectUrl={redirectUrl}></GoogleLoginButton>
        {errorContent}
    </div>;
}
