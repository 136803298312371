import React, { useEffect, useState } from 'react';
import './CalendarView.css';
import { useNavigate } from 'react-router-dom';
import { addDay, dateToString, isSameDay } from '../dates';
import { Recipe, plannedMeals, imageIdToUrl, PlannedMeal } from '../data';
import { RecipeServingTuple, ShopMeals } from '../components/ShoppingListView';

interface DayButtonProps {
    date: Date;
    recipes?: PlannedMeal[];
    isSelected: boolean;
    onStartSelect: (date: Date) => void;
    onEndSelect: (date: Date, isUp: boolean) => void;
}

function PlannedRecipe({ recipe }: { recipe: Recipe }) {
    const imgUrl = imageIdToUrl(recipe?.image_id);
    return <div className='day-button-recipe'>
        <div className='day-button-recipe-name'><div>{recipe.name}</div></div>
        {imgUrl ? <div className='day-button-img'><img src={imgUrl}></img></div> : <></>}
    </div>;
}

function DayButton({ date, recipes, isSelected, onStartSelect, onEndSelect }: DayButtonProps) {
    const dayNum = date.getDate();
    const isToday = isSameDay(new Date(), date);
    const wrapperClasses = 'day-button-wrapper' + (isSelected ? ' selected' : '');
    const dayTextClasses = 'day-button-day' + (isToday ? ' today' : '');

    const onPointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!isPressed(e)) return;

        onEndSelect(date, false);
    };

    return <div className={wrapperClasses}
        onPointerDown={e => { e.preventDefault(); onStartSelect(date);}}
        onPointerEnter={onPointerEnter}
        onPointerUp={_ => onEndSelect(date, true)}>
        <div className={dayTextClasses}>{dayNum}</div>
        <div className='day-button-recipes'>
            {recipes?.map((r, index) => <PlannedRecipe key={index} recipe={r.recipe}></PlannedRecipe>)}
        </div>
    </div>
}

function isPressed(e: React.PointerEvent<HTMLDivElement>): boolean {
    return (e.buttons & 1) === 1;
}

function buildCalendar(): Date[] {
    const today = new Date();
    const dayInWeek = today.getDay();
    const mondayThisWeek = addDay(today, dayInWeek === 0 ? -6 : -(dayInWeek - 1));
    const days = new Array(21).fill(0).map((_, index) => addDay(mondayThisWeek, index - 7));
    return days;
}

function CalendarView() {
    const navigate = useNavigate();
    const [days] = useState<Date[]>(buildCalendar());
    const [dateMealMap, setDateMealMap] = useState<Map<Date, PlannedMeal[]>>(new Map());
    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [firstDate, setFirstDate] = useState<number | undefined>(undefined);
    const [lastDate, setLastDate] = useState<number | undefined>(undefined);

    const hasSelection = !isSelecting && (firstDate !== undefined) && (lastDate !== undefined);

    useEffect(() => {
        plannedMeals(days[0], days[days.length]).then(plannedMeals => {
            const dateMealMap = new Map<Date, PlannedMeal[]>();
            for (const meal of plannedMeals) {
                const date = new Date(meal.date);
                const foundDate = days.find(d => isSameDay(date, d));
                if (foundDate) {
                    let recipes = dateMealMap.get(foundDate);
                    if (!recipes) {
                        dateMealMap.set(foundDate, recipes = []);
                    }
                    recipes.push(meal);
                }
            }
            setDateMealMap(dateMealMap);
        });
    }, [])

    const onStartSelect = (date: Date) => {
        setLastDate(undefined);
        const index = days.indexOf(date);
        setFirstDate(index);
        setLastDate(index);
        setIsSelecting(true);
    };

    const onEndSelect = (date: Date, isUp: boolean) => {
        if (!isSelecting) return;

        setLastDate(days.indexOf(date));
        if (isUp) {
            setIsSelecting(false);
        }
    };

    const onPointerEnter = (e: React.PointerEvent<HTMLDivElement>) => {
        if (isSelecting && !isPressed(e)) {
            setIsSelecting(false);
        }
    };

    const onPlan = () => {
        const range = [firstDate!, lastDate!].sort((a, b) => a - b);
        const start = dateToString(days[range[0]]);
        const end = dateToString(days[range[1]]);

        navigate(`/plan?dates=${encodeURIComponent(`${start}_${end}`)}`);
    };

    const onShowShoppingList = async () => {
        const range = [firstDate!, lastDate!].sort((a, b) => a - b);
        let recipesToShop: ShopMeals = [];
        for (let i = range[0]; i <= range[1]; i++) {
            const date = days[i];
            const plannedMeals = dateMealMap.get(date);
            if (plannedMeals) {
                recipesToShop.push(...plannedMeals.map(r => r.servings ? [r.recipe.id, r.servings] as RecipeServingTuple : r.recipe.id));
            }
        }

        const meals = encodeURIComponent(JSON.stringify(recipesToShop))
        navigate(`/shopping-list?meals=${meals}`);
    };

    return (
        <div className="calendar-view" onPointerEnter={onPointerEnter}>
            <div>
                <button disabled={!hasSelection} onClick={onPlan}>Planera</button>
                <button disabled={!hasSelection} onClick={onShowShoppingList}>Inköpslista</button>
            </div>
            <div className="calendar-grid">
                {days.map((d, index) => {
                    let isSelected = false;
                    if (firstDate !== undefined && lastDate !== undefined) {
                        if (firstDate < lastDate) {
                            isSelected = firstDate <= index && index <= lastDate;
                        }
                        else {
                            isSelected = lastDate <= index && index <= firstDate;
                        }
                    }

                    return <DayButton
                        key={index}
                        date={d}
                        recipes={dateMealMap.get(d)}
                        isSelected={isSelected}
                        onStartSelect={onStartSelect}
                        onEndSelect={onEndSelect}></DayButton>
                })}
            </div>
        </div>
    );
}

export default CalendarView;
