import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { User, getUser } from "../data";

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
    Error
}

export interface AuthStateCommon {
}

export interface AuthStateSignedIn extends AuthStateCommon {
    status: AuthStatus.SignedIn;
    user: User;
    editableUsers: Set<number>;
}

export interface AuthStateError extends AuthStateCommon {
    status: AuthStatus.Error;
    error: string;
}

export interface AuthStateOther extends AuthStateCommon {
    status: AuthStatus.Loading | AuthStatus.SignedOut;
}

export type AuthState = AuthStateSignedIn | AuthStateError | AuthStateOther;

const defaultState: AuthState = {
    status: AuthStatus.Loading,
};

export interface AuthContextControl {
    refresh(): Promise<void>;
}

export const AuthContext = React.createContext<AuthState>(defaultState);
export const AuthContextControl = React.createContext<AuthContextControl | undefined>(undefined);

export function useAuthContext() {
    return useContext(AuthContext);
}

export function useAuthContextControl() {
    return useContext(AuthContextControl);
}

export const AuthIsSignedIn = ({ children }: PropsWithChildren) => {
    const { status } = useContext(AuthContext);
    return <>{status === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: PropsWithChildren) => {
    const { status } = useContext(AuthContext);
    return <>{(status === AuthStatus.SignedOut || status === AuthStatus.Error) ? children : null}</>;
};

const AuthProvider = ({ children }: PropsWithChildren) => {
    const [state, setState] = useState<AuthState>(defaultState);

    const refresh = useCallback(async () => {
        try {
            const res = await getUser();
            console.log("getUser", res);
            if (res) {
                setState({ status: AuthStatus.SignedIn, user: res, editableUsers: new Set(res.editable_users) });
            }
            else {
                setState({ status: AuthStatus.SignedOut });
            }
        }
        catch (e) {
            setState({ status: AuthStatus.Error, error: "" + e });
        }
    }, [setState]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    if (state.status === AuthStatus.Loading) {
        return null;
    }

    return <AuthContext.Provider value={state}>
        <AuthContextControl.Provider value={{ refresh }}>
            {children}
        </AuthContextControl.Provider>
    </AuthContext.Provider>;
};

export default AuthProvider;