import React, { ReactElement, useEffect, useState } from 'react';
import './AddIngredient.css'
import AddIngredientTextInput, { IngredientOption } from './AddIngredientTextInput';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Ingredient, QuantityValue } from '../data';
import { parseQuantity } from '../parsing';

export type { IngredientOption };

// TODO: Load from backend
const units: string[] = [
    "",
    "g",
    "kg",
    "ml",
    "l",
];

export default function AddIngredient({ ingredients, onAdd }: { ingredients: IngredientOption[]; onAdd: (ingredient: Ingredient) => void; }) {
    const [ingredientValue, setIngredientValue] = useState<IngredientOption | null | undefined>();
    const [quantity, setQuantity] = useState<string>("");
    const [unit, setUnit] = useState<string>("");

    const onClickAdd = () => {
        if (!ingredientValue) return;
        
        const name = (ingredientValue.inputValue || ingredientValue.name).toLowerCase();
        const quantityValue = quantity ? parseQuantity(quantity) : undefined;
        onAdd({ name, quantity: quantityValue, unit, scale_outcome: 'none' });
    };

    return <div className='add-ingredient-wrapper'>
        <TextField label="Mängd" value={quantity} onChange={e => setQuantity(e.target.value)}/>
        <Autocomplete
            value={unit}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="add-ingredient-unit-input"
            onChange={(_e, newValue) => {
                if (!newValue) {
                    setUnit("");
                    return;
                }

                setUnit(newValue);
            }}
            options={units}
            sx={{ width: 100 }}
            renderOption={(props, option) => <li {...props}>{option ? option : <i>Ingen enhet</i>}</li>}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="Enhet" />
            )}
        />
        <AddIngredientTextInput ingredients={ingredients} value={ingredientValue} onSetValue={setIngredientValue}></AddIngredientTextInput>
        <Button
            variant="contained"
            disabled={!ingredientValue}
            onClick={onClickAdd}
        >Lägg till</Button>
    </div>;
}