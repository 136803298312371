import React, { useEffect, useState } from 'react';
import './Meme.css';


interface Meme {
    title: string;
    url: string;
}

function Meme() {
    const [meme, setMeme] = useState<Meme | undefined>(undefined);

    useEffect(() => {
        const request = new Request("https://meme-api.com/gimme");
        fetch(request).then(async res => {
            const json = await res.json();
            setMeme({ title: json.title, url: json.url });
        });
    }, []);

    let content = <></>;
    if (meme) {
        content = (
            <div className='meme-wrapper'>
                <div className='meme-post'>
                    
                    <img src={meme.url}></img>
                    <h1 className='meme'>{meme.title}</h1>
                </div>
                <div className='meme-header'>
                </div>
            </div>
        );
    }

    return <div>
        {content}
    </div>
}

export default Meme;