import React, { ReactElement, useEffect, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { CooklangRecipeWithMetadata, cookRecipe } from '../data';
import Recipe, { ParsedRecipeWithMetadata } from '../components/Recipe';

function withImageUrls(recipe: CooklangRecipeWithMetadata | undefined): ParsedRecipeWithMetadata | undefined {
    if (!recipe) return undefined;

    const stepImages = new Map<number, string>();
    for (const step of recipe.step_images) {
        stepImages.set(step.step + 1, `/image/${step.image_id}`);
    }

    return {
        recipe: recipe.recipe,
        image: recipe.image_id ? `/image/${recipe.image_id}` : undefined,
        stepImages
    };
}

export interface LoaderData {
    recipe: ParsedRecipeWithMetadata | undefined;
    servings?: number;
}

export async function loader({ params, request }: LoaderFunctionArgs): Promise<LoaderData> {
    const recipeId = params.recipeId;
    if (!recipeId) {
        throw new Error("Missing recipeId");
    }
    const url = new URL(request.url);
    const parsedServings = parseInt(url.searchParams.get("servings") || "", 10);
    const servings = Number.isFinite(parsedServings) ? parsedServings : undefined

    const recipe = await cookRecipe(parseInt(recipeId, 10));
    return {
        recipe: withImageUrls(recipe),
        servings,
    };
}

function RecipeView() {
    const { recipe, servings } = useLoaderData() as LoaderData;

    let inner: ReactElement;
    if (recipe) {
        inner = <Recipe recipe={recipe} servings={servings} fractionAsNumber={false}></Recipe>;
    }
    else {
        inner = <div>
            <h3>Recept hittades inte</h3>
            Verifera att länken är giltig.
        </div>;
    }

    return (
        <div className="recipe">
            <div className='recipe-wrapper'>
                {inner}
            </div>
        </div>
    );
}

export default RecipeView;
