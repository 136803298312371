import { createContext, useContext } from 'react';

export interface ScaleTarget {
    factor: number;
    index?: number;
}

export interface RecipeDisplayContext {
    fractionAsNumber: boolean;
    scale?: ScaleTarget;
}

export const RecipeDisplayContext = createContext<RecipeDisplayContext>({
    fractionAsNumber: false,
});

export function useRecipeDisplayContext() {
    return useContext(RecipeDisplayContext);
}
