import React, { useEffect, useState } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { languageId, setupLanguage, themeId } from '../cooklangSupport';

const options: editor.IStandaloneEditorConstructionOptions = {
    wordWrap: "on"
};

function EditRecipe({ text, onTextChanged }: { text?: string; onTextChanged?: (value: string) => void; }) {
    const monaco = useMonaco();
    const [languageInit, setLanguageInit] = useState<boolean>(false);

    useEffect(() => {
        if (!monaco) return;

        setupLanguage(monaco);
        setLanguageInit(true);
    }, [monaco]);

    if (!languageInit) {
        return <div>Loading...</div>
    }

    return (<Editor
        language={languageId}
        theme={themeId}
        defaultValue={text}
        value={text}
        options={options}
        onChange={(e) => onTextChanged?.(e || "")}/>);
}

export default EditRecipe;