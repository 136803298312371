import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import './Recipes.css';
import RecipesView from '../components/RecipesView';
import { loadRecipes, Recipe } from '../data';

function Recipes() {
    let [recipes, setRecipes] = useState<Recipe[] | null>(null);

    useEffect(() => {
        loadRecipes().then(recipes => {
            setRecipes(recipes);
        });
    }, []);

    return (
        <div className="Recipes">
            <div className='recipesview-wrapper'>
                <RecipesView recipes={recipes}></RecipesView>
            </div>
        </div>
    );
}

export default Recipes;
