import React, { PropsWithChildren } from 'react';
import "./LinkButton.css";

function LinkButton({ href, children }: PropsWithChildren<{ href: string; }>) {
    return ( 
        <div className="covered-div">
            <a className="cover-link" href={href}></a>
            {children}
        </div>

    );
}

export default LinkButton;