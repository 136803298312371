import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { PlannedMeal, Recipe, dateToString, plannedMeals as getPlannedMeals, imageIdToUrl } from '../data';
import { addDay, isSameDay } from '../dates';
import LinkButton from '../components/LinkButton';

interface Day {
    date: Date;
    recipes: PlannedMeal[];
}

function getDateName(date: Date): string {
    const today = new Date();
    if (isSameDay(date, addDay(today, -1))) return "Igår";
    if (isSameDay(date, today)) return "Idag";
    if (isSameDay(date, addDay(today, 1))) return "Imorgon";
    if (isSameDay(date, addDay(today, 2))) return "Övermorgon";

    return dateToString(date)!;
}

function DayRecipe({ meal }: { meal: PlannedMeal }) {
    const recipe = meal.recipe;
    const imageUrl = imageIdToUrl(recipe.image_id);

    return <div className='home-day-recipe-wrapper'>
        <LinkButton href={`/recipe/${recipe.id}${meal.servings ? "?servings=" + meal.servings : ""}`}>
            <div className='home-day-recipe'>
                <div className='home-day-recipe-name'>{recipe.name}</div>
                {imageUrl ? <img className='home-day-recipe-img' src={imageUrl}></img> : <></>}
            </div>
        </LinkButton>
    </div>
}

function Day({ day, highlight }: { day: Day, highlight: boolean; }) {
    const recipes = day.recipes.map((r, index) => <DayRecipe key={index} meal={r}></DayRecipe>);
    return <div className={'home-day-wrapper' + (highlight ? ' highlight' : '')}>
        <div className='home-day-date'>
            {getDateName(day.date)}
        </div>
        <div className='home-day-recipes'>
            {recipes.length ? recipes : <div className='home-day-recipe unplanned'>Inget planerat</div>}
        </div>
    </div>;
}

function App() {
    const todayRef = useRef<HTMLDivElement>(null);
    const today = new Date();

    const [plannedMeals, setPlannedMeals] = useState<Day[] | undefined>();

    useEffect(() => {
        const daysToShow = new Array(15).fill(0).map((_, index) => addDay(today, index - 5));
        getPlannedMeals(daysToShow[0], daysToShow[daysToShow.length - 1]).then(plannedMeals => {
            let days = new Map<string, Day>();
            for (const meal of plannedMeals) {
                let entry = days.get(meal.date);
                if (!entry) {
                    days.set(meal.date, entry = { date: new Date(meal.date), recipes: [] });
                }
                entry.recipes.push(meal);
            }

            // Add remaining days
            for (const day of daysToShow) {
                const key = dateToString(day)!;
                if (!days.has(key)) {
                    days.set(key, { date: day, recipes: [] });
                }
            }

            const values = Array.from(days.values()).sort((a, b) => a.date.getTime() - b.date.getTime())
            setPlannedMeals(values);
        });
    }, []);

    useEffect(() => {
        todayRef.current?.scrollIntoView();
    }, [plannedMeals])

    return (
        <div className="App">
            {plannedMeals ? plannedMeals.map(meal => {
                const isToday = isSameDay(today, meal.date);
                return <div key={meal.date.toISOString()} ref={isToday ? todayRef : null}><Day day={meal} highlight={isToday}></Day></div>;
            }) : <span>Loading...</span>}
        </div>
    );
}

export default App;
