import { Monaco } from "@monaco-editor/react";

export const languageId = "cooklang";
export const themeId = "cooklangTheme";

export function setupLanguage(monaco: Monaco) {
    // Check if the languge has already been registered.
    const id = monaco.languages.getEncodedLanguageId(languageId);
    if (id !== 0) {
        return;
    }

    // Register a new language
    monaco.languages.register({ id: languageId });

    // Register a tokens provider for the language
    monaco.languages.setMonarchTokensProvider(languageId, {
        "tokenizer": {
            "root": [
                {"include": "#ingredients"},
                {"include": "#cookware"},
                {"include": "#timers"},
                {"include": "#metadata"},
                {"include": "#conditionals"},
                {"include": "#brackets"},
                {"include": "#numbers-strings"},
                {"include": "#comments"}
            ],
            "#ingredients": [
                [/@/, { token: "symbol.operator", next: "@ingredient"}]
            ],
            "ingredient": [
                [/([^{]+)({)/, [ "ingredients", { token: "symbol.brackets", next: "@quantity" }]],
                [/([^ .,;@{}]+)/, { token: "ingredients", next: "@popall" }],
            ],
            "quantity": [
                [/%/, "symbol.operator"],
                [/[^%}]+/, "quantity"],
                [/}/, { token: "symbol.brackets", next: "@popall"}]
            ],
            "#cookware": [
                [/#[^{#@~]+{[^}]*}|#[^ ]+/, "cookware"]
            ],
            "#timers": [
                [/~\\{/, {"token": "constant.number", "next": "@timers"} ]
            ],
            "timers": [
                [/(%|~)/, "symbol.operator"],
                [/\\b\\d+\\b/, "constant.number"],
                [/[A-Za-z]+/, "constant.string"],
                [/\\}/, { token: "symbol.operator", next: "@pop" }],
            ],
            "#metadata": [
                [/^>>/, { "token": "symbol.operator", "next": "@metadata" }]
            ],
            "metadata": [
                [/(TODO|XXX|FIXME)/, "todo"],
                [/[A-Za-z0-9]+/, "identifier"],
                [/(>>|: )/, "symbol.operator"],
                [/$/, { token: "constant.string", next: "@pop" }],
            ],
            "#conditionals": [
                [/(@|#|~|%|°)/, "symbol.operator"]
            ],
            "#brackets": [
                [/(\{|\})/, "symbol.brackets"],
                [/(\(|\})/, "symbol.brackets"],
                [/(\[|\})/, "symbol.brackets"],
            ],
            "#numbers-strings": [
                [/\b([0-9]+|0x[0-9a-fA-F]*)\b|'.'/,  "constant.number" ]
            ],
            "#comments": [
                [/(\\[-.*-\\])/, "comment" ],
                [/-- /, { "token": "comment", "next": "@comment" } ],
            ],
            "comment": [
                [/$/, { token: "comment", next: "@pop" }],
            ]
        },
    });

    // Define a new theme that contains only rules that match this language
    monaco.editor.defineTheme(themeId, {
        base: "vs",
        inherit: true,
        rules: [
            { token: "identifier", foreground: "A000F0" },
            { token: "symbol.operator", foreground: "0000F0" },
            { token: "symbol.brackets", foreground: "808000" },
            { token: "ingredients", foreground: "ff0000", fontStyle: "bold" },
            { token: "cookware", foreground: "FFA500" },
            { token: "quantity", foreground: "FFA5A5" },
            { token: "constant.number", foreground: "FFA500" },
        ],
        colors: {
            "editor.foreground": "#000000",
        },
    });

    // Register a completion item provider for the new language
    monaco.languages.registerCompletionItemProvider(languageId, {
        provideCompletionItems: (model, position) => {
            const line = model.getLineContent(position.lineNumber);
            if (!line.startsWith(">> ")) {
                return undefined;
            }
            const word = model.getWordUntilPosition(position);
            if (word.startColumn !== 4) {
                return undefined;
            }

            const range = {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endColumn: word.endColumn,
            };
            function metaDataSuggestion(name: string) {
                return {
                    label: name,
                    kind: monaco.languages.CompletionItemKind.Field,
                    insertText: `${name}: \${1:${name}}`,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    range: range,
                };
            }
            const suggestions = [
                metaDataSuggestion("source"),
                metaDataSuggestion("servings"),
                metaDataSuggestion("time"),
                metaDataSuggestion("prep time"),
                metaDataSuggestion("cook time"),
            ];
            return { suggestions: suggestions };
        },
    });
}
