import React, { ReactElement, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { capitalizeFirst } from '../textFormattings';

export interface IngredientOption {
    inputValue?: string;
    name: string;
    category?: string | null;
}

export interface AddIngredientTextInputProps {
    value?: IngredientOption | null;
    onSetValue: (value: IngredientOption | null) => void;
    ingredients: IngredientOption[];
}

const filter = createFilterOptions<IngredientOption>();

export default function AddIngredientTextInput({ ingredients, value, onSetValue }: AddIngredientTextInputProps) {
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    onSetValue({
                        name: newValue,
                    });
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    onSetValue({
                        name: newValue.inputValue,
                    });
                } else {
                    onSetValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="add-ingredient-input"
            options={ingredients}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{capitalizeFirst(option.name)}</li>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="Ingrediens" />
            )}
        />
    );
}


