import React, { useState, useCallback } from 'react';
import './PlanView.css';
import RecipesView from '../components/RecipesView';
import SelectPortions from '../components/SelectPortions';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { datesInRange, getMonth, getWeekDay, isSameDay } from '../dates';
import { ColumnDefinition, RecipeRow } from '../components/RecipesTable';
import { PlanMeal, PlanMeals, Recipe, loadRecipes, planMeals, plannedMeals } from '../data';

// Recipe id -> PlanEntry
type RecipeToPlanMap = Map<number, PlanEntry>;

interface LoaderData {
    recipes: RecipeRow<RecipeExtra>[];
    dates: Date[];
    plannedMealsMap: RecipeToPlanMap;
}

export async function loader({ request }: LoaderFunctionArgs): Promise<LoaderData> {
    const recipesPromise = loadRecipes();
    const url = new URL(request.url);
    const datesQuery = url.searchParams.get("dates") || "";
    const dateParts = datesQuery.split("_");
    const start = new Date(dateParts[0]);
    const end = new Date(dateParts[1]);
    const dates = datesInRange(start, end);

    const [recipes, res] = await Promise.all([
        recipesPromise,
        plannedMeals(start, end)
    ]);
    const plannedMealsMap = new Map<number, PlanEntry>();
    for (const meal of res) {
        const mealDate = new Date(meal.date);
        const foundDate = dates.find(d => isSameDay(d, mealDate));
        if (foundDate) {
            plannedMealsMap.set(meal.recipe.id, { date: foundDate, recipe: meal.recipe, scale: meal.servings ?? 1 });
        }
    }

    return {recipes, dates, plannedMealsMap};
}

interface IPlanDay {
    id: number;
    name: string;
}

function SelectDay({ days, value, onSelectDay }: { days: IPlanDay[], value?: number, onSelectDay?: (day?: IPlanDay | null) => void; }) {
    const onChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!onSelectDay) return;

        const valueStr = e.target.value;
        if (!valueStr) {
            onSelectDay(null);
            return;
        }
        const value = parseInt(valueStr, 10);
        const day = days.find(d => d.id === value);
        onSelectDay(day);
    }, [days, onSelectDay]);

    return <select onChange={onChange} value={value}>
        <option value={0}>---</option>
        {days.map(d => {
            return <option key={d.id} value={d.id}>{d.name}</option>
        })}
    </select>;
}



function PlannedRecipe({ recipe }: { recipe: Recipe }) {
    return <div>
        {recipe.name}
    </div>;
}

function PlanDay({ date, recipes }: { date: Date; recipes: Recipe[] }) {
    const day = getWeekDay(date);
    const month = getMonth(date);

    let content: React.ReactElement;
    if (recipes.length) {
        content = <div className='plan-day-recipes'>
            {recipes.map((r, index) => <PlannedRecipe key={index} recipe={r}></PlannedRecipe>)}
        </div>;
    }
    else {
        content = <div className='plan-day-empty'>
            Inget planerat
        </div>;
    }

    return <div className='plan-day'>
        <div className='plan-day-header'>
            <div className='plan-day-header-day'>{day}</div>
            <div className='plan-day-header-date'>{date.getDate()} {month}</div>
        </div>
        {content}
    </div>;
}

interface PlanEntry {
    date: Date;
    recipe: Recipe;
    scale: number;
}

interface RecipeExtra {
    plannedDay?: number;
    scaling?: number;
}

function PlanView() {
    const { recipes, dates, plannedMealsMap } = useLoaderData() as LoaderData;
    const [plannedMeals, setPlannedMeals] = useState<RecipeToPlanMap>(plannedMealsMap);

    const planDays: IPlanDay[] = dates.map(d => {
        return { id: d.getTime(), name: `${getWeekDay(d)} ${d.getDate()}/${(d.getMonth() + 1)}`}
    });

    const plannedMealsValues = Array.from(plannedMeals.values());
    const plannedRecipes = new Map<number, { date: number; scaling: number; }>();
    for (const { recipe, date, scale } of plannedMealsValues) {
        plannedRecipes.set(recipe.id, { date: date.getTime(), scaling: scale });
    }

    for (const recipe of recipes) {
        const entry = plannedRecipes.get(recipe.id);
        recipe.plannedDay = entry?.date;
        recipe.scaling = entry?.scaling;
    }

    const onSave = async () => {
        let request: PlanMeals[] = [];
        for (const date of dates) {
            const planned = plannedMealsValues
                .filter(e => e.date === date)
                .map<PlanMeal>(e => ({ recipe_id: e.recipe.id, servings: e.scale }));

            request.push({ date, planned });
        }

        try {
            await planMeals(request);
        }
        catch (e) {
            console.error("Planned meals failed", e);
        }
    };

    const onSelectDay = useCallback((recipe: RecipeRow<RecipeExtra>, day?: IPlanDay | null) => {
        if (!day) {
            setPlannedMeals(plannedMeals => {
                plannedMeals.delete(recipe.id);
                return new Map(plannedMeals)
            });
            return;
        }

        const date = dates.find(d => d.getTime() === day.id);
        if (date) {
            setPlannedMeals(plannedMeals => {
                plannedMeals.set(recipe.id, { recipe, date, scale: recipe.servings ?? 1 });
                return new Map(plannedMeals)
            });
        }
    }, [dates]);

    const onSelectPortion = useCallback((recipe: RecipeRow<RecipeExtra>, scale: number) => {
        setPlannedMeals(plannedMeals => {
            const entry = plannedMeals.get(recipe.id);
            if (entry) {
                entry.scale = scale;
                return new Map(plannedMeals)
            }

            return plannedMeals;
        });
    }, []);


    const extraColumns: ColumnDefinition<RecipeRow<RecipeExtra>>[] = [
        {
            name: "Dag",
            build: recipe => <SelectDay days={planDays} value={recipe.plannedDay} onSelectDay={day => onSelectDay(recipe, day)}></SelectDay>,
            sort: (a, b) => (a.plannedDay ?? -1) - (b.plannedDay ?? -1)
        },
        {
            name: "Portioner",
            build: recipe => typeof recipe.plannedDay === "number" ?
                <SelectPortions scaling={recipe.scaling ?? 1} onSelectPortion={scale => onSelectPortion(recipe, scale)}></SelectPortions> :
                <></>,
            sort: (a, b) => (a.scaling ?? -1) - (b.scaling ?? -1)
        }
    ];

    return (
        <div className="plan-view">
            <div className='plan-view-days'>
                <div>
                    Att planera
                </div>
                {dates.map((d, index) => {
                    const recipes = plannedMealsValues
                        .filter(e => e.date === d)
                        .map(e => e.recipe);
                    return <PlanDay key={index} date={d} recipes={recipes}></PlanDay>;
                })}
                <div>
                    <button onClick={onSave}>Spara</button>
                </div>
            </div>
            <div className='plan-view-recipes'>
                <RecipesView recipes={recipes} extraColumns={extraColumns}></RecipesView>
            </div>
        </div>
    );
}

export default PlanView;
