import { createContext, useContext, useReducer, PropsWithChildren, Reducer, Dispatch } from 'react';
import { AisleWithIngredients, CooklangNumber, Ingredient, IngredientsCategory, QuantityNumber, QuantityValue, categorizeIngredient } from '../data';
import { parseQuantity } from '../parsing';

export interface IngredientWithId extends Ingredient {
    uniqueId: string;
}

export interface IngredientSource {
    name?: string;
    ingredient: IngredientWithId;
}

export interface ShoppingListIngredient extends Ingredient {
    uniqueId: string;
    sources: IngredientSource[];
}

export interface ShoppingListCategory extends IngredientsCategory {
    uniqueId: string;
    id?: number;
    name: string | null;
    items: ShoppingListIngredient[];
}

export type ShoppingList = ShoppingListCategory[];

export enum IngredientState {
    None,
    Added,
    Removed
}

export interface ShoppingListContext {
    shoppingList: ShoppingList;
    aisles: AisleWithIngredients[];
    ingredientToCategory: Map<string, AisleWithIngredients>;
    usedIngredients: Map<string, IngredientState>;
}

export const noneCategoryId = "none";
let uniqueIdCtr = 0;
export function nextUniqueId(): string {
    return "c" + (++uniqueIdCtr);
}

export const ShoppingListContext = createContext<ShoppingListContext>({
    shoppingList: [],
    aisles: [],
    ingredientToCategory: new Map(),
    usedIngredients: new Map()
});
export const ShoppingListDispatchContext = createContext<Dispatch<Action>>(null!);

export function useShoppingList() {
    return useContext(ShoppingListContext);
}

export function useShoppingListDispatch() {
    return useContext(ShoppingListDispatchContext);
}

export interface Add {
    type: 'add';
    category?: string | null;
    ingredient: IngredientWithId;
    source?: string;
}

export interface Remove {
    type: 'remove';
    category: string;
    index: number;
}

export interface Skip {
    type: 'skip';
    ingredient: IngredientWithId;
}

export interface ChangeValue {
    type: 'change-value';
    category: string;
    index: number;
    value: string;
}

export interface Move {
    type: 'move';
    fromCategory: string;
    toCategory: string;
    fromIndex: number;
    toIndex: number;
}

export type Action = Add | Remove | Skip | ChangeValue | Move;

function asRegular(v: CooklangNumber): number {
    if (v.type === "regular") return v.value;

    const { whole, num, den } = v.value;
    return whole + num / den;
}

function addNumber(a: CooklangNumber, b: CooklangNumber): CooklangNumber {
    return {
        type: "regular",
        value: asRegular(a) + asRegular(b)
    }
}

function sumSources(sources: IngredientSource[]): QuantityValue | undefined {
    const first = sources[0].ingredient.quantity;
    if (!first) return undefined;

    if (first.type === "text" || first.type === "range") {
        throw new Error("Unsupported types to sum");
    }

    let sum = first.value;
    for (let i = 1; i < sources.length; ++i) {
        sum = addNumber(sum, (sources[i].ingredient.quantity! as QuantityNumber).value);
    }

    return {
        type: "number",
        value: sum
    };
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

function move<T>(
    source: T[], destination: T[], fromIndex: number, toIndex: number
): [source: T[], destination: T[]] {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(fromIndex, 1);

    destClone.splice(toIndex, 0, removed);

    return [sourceClone, destClone];
}

export function shoppingListReducer(shoppingList: ShoppingList, action: Action): ShoppingList {
    console.log(action);

    function getCategoryIndexFromName(category?: string | null): number {
        const categoryIndex = category ?
            shoppingList.findIndex(c => c.name === category) :
            shoppingList.findIndex(c => c.uniqueId === noneCategoryId);

        if (categoryIndex === -1) {
            throw new Error("Unknown category " + category);
        }
        return categoryIndex;
    }
    function getCategoryIndexFromId(categoryId: string): number {
        const categoryIndex = shoppingList.findIndex(c => c.uniqueId === categoryId);

        if (categoryIndex === -1) {
            throw new Error("Unknown category id " + categoryId);
        }
        return categoryIndex;
    }

    function removeItem(list: ShoppingList, categoryIndex: number, itemIndex: number): ShoppingList {
        const category = list[categoryIndex];
        return [
            ...list.slice(0, categoryIndex),
            { ...category, items: category.items.slice(0, itemIndex).concat(category.items.slice(itemIndex + 1)) },
            ...list.slice(categoryIndex + 1)
        ];
    }

    function modifyItem(list: ShoppingList, categoryIndex: number, itemIndex: number, modifyItem: (item: ShoppingListIngredient) => ShoppingListIngredient): ShoppingList {
        const copy = Array.from(list);
        const copyItems = Array.from(copy[categoryIndex].items);

        copyItems[itemIndex] = modifyItem(copyItems[itemIndex]);
        copy[categoryIndex] = {
            ...copy[categoryIndex],
            items: copyItems
        };
        return copy;
    }

    switch (action.type) {
        case 'remove': {
            const { category: categoryId, index } = action;
            const categoryIndex = getCategoryIndexFromId(categoryId);
            return removeItem(shoppingList, categoryIndex, index);
        }

        case 'skip': {
            const ingredient = action.ingredient;
            for (let categoryIndex = 0; categoryIndex < shoppingList.length; ++categoryIndex) {
                for (let itemIndex = 0; itemIndex < shoppingList[categoryIndex].items.length; ++itemIndex) {
                    const item = shoppingList[categoryIndex].items[itemIndex];
                    console.log(item, ingredient)
                    if (item.name === ingredient.name) {
                        const sourceIndex = item.sources.findIndex(s => s.ingredient.uniqueId === ingredient.uniqueId);
                        if (sourceIndex !== -1) {
                            const newSources = [...item.sources.slice(0, sourceIndex), ...item.sources.slice(sourceIndex + 1)];
                            if (newSources.length === 0) {
                                shoppingList = removeItem(shoppingList, categoryIndex, itemIndex);
                                --itemIndex;
                            }
                            else {
                                shoppingList = modifyItem(
                                    shoppingList,
                                    categoryIndex,
                                    itemIndex,
                                    item => ({ ...item, sources: newSources, quantity: sumSources(newSources) })
                                );
                            }
                        }
                    }
                }
            }
            
            return shoppingList;
        }

        case 'move': {
            const { fromCategory, toCategory, fromIndex, toIndex } = action;
            if (fromCategory === toCategory) {
                const categoryIndex = shoppingList.findIndex(c => c.uniqueId === fromCategory);
                if (categoryIndex === -1) {

                    throw new Error("Unknown category " + fromCategory);
                }
                const category = shoppingList[categoryIndex];
                return [
                    ...shoppingList.slice(0, categoryIndex),
                    { ...category, items: reorder(category.items, fromIndex, toIndex) },
                    ...shoppingList.slice(categoryIndex + 1)
                ];
            }
            else {
                const sourceIndex = getCategoryIndexFromId(fromCategory);
                const destinationIndex = getCategoryIndexFromId(toCategory);
                const destinationCategoryId = shoppingList[destinationIndex].id;
                if (typeof destinationCategoryId === "number") {
                    const ingredient = shoppingList[sourceIndex].items[fromIndex];
                    categorizeIngredient(destinationCategoryId, ingredient.name);
                }
                const [sourceItems, destinationItems] = move(shoppingList[sourceIndex].items, shoppingList[destinationIndex].items, fromIndex, toIndex);
                const copy = shoppingList.slice();
                copy[sourceIndex] = {
                    ...copy[sourceIndex],
                    items: sourceItems
                };
                copy[destinationIndex] = {
                    ...copy[destinationIndex],
                    items: destinationItems
                };
                return copy;
            }
        }

        case 'change-value': {
            const { category, index, value } = action;
            const categoryIndex = getCategoryIndexFromId(category);
            const newQuantity: QuantityValue = parseQuantity(value);
            // TODO: Figure out how this should interact with the 'skip' action.
            return modifyItem(shoppingList, categoryIndex, index, item => ({ ...item, quantity: newQuantity }));
        }

        case 'add': {
            const { category: categoryName, ingredient, source } = action;
            const categoryIndex = getCategoryIndexFromName(categoryName);

            const addIngredient = () => {
                return [
                    ...shoppingList.slice(0, categoryIndex),
                    { ...category, items: [...category.items, { ...ingredient, uniqueId: nextUniqueId(), sources: [ { name: source, ingredient } ] }] },
                    ...shoppingList.slice(categoryIndex + 1)
                ];
            };

            const category = shoppingList[categoryIndex];
            const newQuantity = ingredient.quantity;
            if (!newQuantity || newQuantity.type === "text" || newQuantity.type === "range") {
                return addIngredient();
            }

            for (const index of Array.from(category.items.keys())) {
                const item = category.items[index];
                if (item.name === ingredient.name && item.quantity && item.unit === ingredient.unit) {
                    const quantity = item.quantity;
                    if (quantity.type === "text" || quantity.type === "range") {
                        continue;
                    }

                    const sources = item.sources.concat([{ name: source, ingredient }]);
                    const sumQuantity = sumSources(sources);
                    return modifyItem(shoppingList, categoryIndex, index, item => ({ ...item, sources, quantity: sumQuantity }));
                }
            }

            return addIngredient();
        }

    }
    return shoppingList;
}

export function shoppingListContextReducer(context: ShoppingListContext, action: Action): ShoppingListContext {
    let { aisles, shoppingList, ingredientToCategory, usedIngredients } = context;

    function getCategoryIndexFromId(categoryId: string): number {
        const categoryIndex = shoppingList.findIndex(c => c.uniqueId === categoryId);

        if (categoryIndex === -1) {
            throw new Error("Unknown category id " + categoryId);
        }
        return categoryIndex;
    }

    switch (action.type) {
        case 'remove': {
            const ingredient = shoppingList[getCategoryIndexFromId(action.category)].items[action.index];
            const state = usedIngredients.get(ingredient.uniqueId) ?? IngredientState.None;
            
            if (state === IngredientState.Added) {
                usedIngredients = new Map(usedIngredients);
                usedIngredients.set(ingredient.uniqueId, IngredientState.Removed);
            }
            break;
        }

        case 'add': {
            usedIngredients = new Map(usedIngredients);
            usedIngredients.set(action.ingredient.uniqueId, IngredientState.Added);
            break;
        }

        case 'skip': {
            usedIngredients = new Map(usedIngredients);
            usedIngredients.set(action.ingredient.uniqueId, IngredientState.Removed);
            break;
        }

        case 'move': {
            const { fromCategory, toCategory, fromIndex } = action;
            if (fromCategory !== toCategory) {
                const sourceIndex = getCategoryIndexFromId(fromCategory);
                const destinationIndex = getCategoryIndexFromId(toCategory);
                const destinationCategoryId = shoppingList[destinationIndex].id;
                const ingredient = shoppingList[sourceIndex].items[fromIndex];
                if (typeof destinationCategoryId === "number") {
                    categorizeIngredient(destinationCategoryId, ingredient.name);
                    let aisle = aisles.find(a => a.id === destinationCategoryId);
                    if (aisle) {
                        ingredientToCategory = new Map(ingredientToCategory);
                        ingredientToCategory.set(ingredient.name, aisle);
                    }
                }
                else {
                    ingredientToCategory = new Map(ingredientToCategory);
                    ingredientToCategory.delete(ingredient.name);
                }
            }
        }
    }

    return {
        shoppingList: shoppingListReducer(shoppingList, action),
        aisles,
        ingredientToCategory,
        usedIngredients
    };
}
