import { QuantityValue } from "./data";


export function parseQuantity(value: string): QuantityValue {
    const parsedValue = parseFloat(value);
    const newQuantity: QuantityValue = Number.isFinite(parsedValue) ? {
        type: 'number',
        value: { type: 'regular', value: parsedValue }
    } : {
        type: 'text',
        value
    };

    return newQuantity;
}